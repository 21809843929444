<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <p class="display-1 text--primary">
          {{ $t("menu.producer") }}
        </p>
      </v-list-item-content>
    </v-list-item>
    <v-data-table
      checkbox-color="primary"
      :headers="headers"
      :items="producers"
      v-model="selectedItems"
      :show-select="showCheckBox"
      :loading="loading"
      :options.sync="options"
      :footer-props="{
        ...rowsPerPageItemsOptions,
        itemsPerPageText: $t('general.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
      }"
      :server-items-length="totalFromServer"
      :no-results-text="$t('general.ui.not_search_found')"
      :no-data-text="$t('general.ui.not_data_found')"
      :loading-text="$t('general.notification.loadData')"
    >
      <template v-slot:top>
        <v-row class="d-flex justify-space-between align-center my-3">
          <v-col lg="4" md="4" sm="12" xl="4">
            <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replace" v-on:keyup.ctrl.86="replace"
                          dense
                          filled
                          rounded
                          color="primary"
                          v-model="producerFilters.stringFilter"
                          append-icon="mdi-magnify"
                          :label="$t('general.titles.filter')"
                          :clearable="true"
                          @keypress.enter="searchForText"
                          @click:clear="searchAll"
                          @click:append="searchForText"
                          single-line
                          hide-details
            />
          </v-col>
          <v-col lg="4" md="4" sm="12" xl="4">
            <v-btn
              small
              tile
              :elevation="0"
              color="gray"
              style="border-radius: 1.5em;"
              @click="checkBoxShow"
              class="mr-1"
              :disabled="!(producers && producers.length > 0)"
            >
                <span v-if="!showCheckBox">
                  <v-icon small color="neuter" left
                  >mdi-checkbox-multiple-marked-outline</v-icon
                  >
                  <span class="neuter--text">{{
                      $t("general.buttons.checksBoxEnable")
                    }}</span>
                </span>
              <span v-else>
                  <v-icon small color="neuter" left
                  >mdi-checkbox-multiple-marked</v-icon
                  >
                  <span>{{ $t("general.buttons.checksBoxDisable") }}</span>
                </span>
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col
            lg="4"
            md="4"
            sm="8"
            xl="4"
            class="text-end d-flex justify-end"
          >
            <v-badge
              bordered
              color="neuter"
              overlap
              :content="cantFilters"
              left
              :value="cantFilters"
            >
              <v-btn
                small
                tile
                :elevation="0"
                color="gray"
                style="border-radius: 1.5em 0 0 1.5em"
                @click="openFilters"
                class="mr-1"
              >
                <v-icon small color="neuter" left>mdi-filter-variant</v-icon>
                <span class="neuter--text">{{
                    $t('general.buttons.filter')
                  }}</span>
              </v-btn>
            </v-badge>
            <v-btn
              small
              tile
              :elevation="0"
              color="gray"
              style="border-radius: 0;"
              @click="exportCSVProducers"
              v-if="check([{ domain: 'Producer', permisions: ['Write'] }])"
              :loading="loadingExport"
              class="mr-1"
            >
              <v-icon small color="neuter" left
              >mdi-download-multiple</v-icon
              >
              <span class="neuter--text">{{
                  $t("general.buttons.export")
                }}</span>
              <template v-slot:loader>
                <v-progress-circular
                  style="position: absolute;
                                                   margin-left: -3px;
                                                   height: 15px;"
                  color="secondary accent-4"
                  indeterminate
                  rounded
                />
              </template>
            </v-btn>

            <v-btn
              small
              tile
              :elevation="0"
              color="gray"
              style="border-radius: 0 1.5em 1.5em 0;"
              v-if="
                      check([{ domain: 'Producer', permisions: ['Write'] }])
                    "
              @click="showOrEditItem(editedItem, true, true)"
            >
              <v-icon small color="neuter" left>mdi-plus</v-icon>
              {{ $t("general.buttons.new") }}
            </v-btn>
            <add-or-edit-dialog
              v-if="showTabDialog"
              :edited-item="editedItem"
              :show-tab-dialog="showTabDialog"
              :is-editable="isEditable"
              @mode:editable="isEditable = $event"
              @on:save="handleSave"
              @on:close="close"
            />
          </v-col>
        </v-row>
        <!--FILTERS TOOLBAR *********************************************************************-->
        <v-toolbar
          class="mb-3"
          outlined
          elevation="0"
          style="border-radius: 0.3em"
          v-if="filtersChips && filtersChips.length > 0"
        >
          <v-row style="display: contents">
            <v-col cols="12">
              <v-chip-group active-class="neuter--text">
                <v-chip
                  v-for="(chip, index) in filtersChips"
                  :key="chip.value"
                  :close="!loadingChips[index]"
                  color="#FFFFFF"
                  small
                  @click:close="cleanFilter(chip, index)"
                >
                  <v-avatar left>
                    <v-icon small>mdi-filter-variant</v-icon>
                  </v-avatar>
                  {{ chip.name }}
                  <v-avatar right v-if="loadingChips[index]">
                    <v-progress-circular indeterminate size="12" width="2" />
                  </v-avatar>
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-toolbar>
        <!--FILTERS TOOLBAR *********************************************************************-->
      </template>
      <template v-slot:item.storageDevices="{ item }">
        <ShowMoreComponent v-if="item.storageDevices.length > 0" :item="item" secondary-field="storageDevices.name" />
        <span v-else>-</span>
      </template>
      <template v-slot:item.observations="{ item }">
        <ReadMore
          v-if="!isEmptyLabels(item.observations)"
          :classes="'mb-0'"
          :font-size="14"
          :max-chars="40"
          :text="translate(item.observations)"
        />
        <span v-else>-</span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="neuter"
              small
              class="mr-2"
              @click="showOrEditItem(item, false, false)"
              v-if="check([{ domain: 'Producer', permisions: ['Read'] }])"
            >
              mdi-eye-outline
            </v-icon>
          </template>
          <span>
            {{ $t("general.buttons.show") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("menu.producer") }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="check([{ domain: 'Producer', permisions: ['Update'] }])"
              color="neuter"
              class="mr-2"
              small
              @click="showOrEditItem(item, true, false)"
            >
              mdi-square-edit-outline
            </v-icon>
          </template>
          <span>
            {{ $t("general.buttons.edit") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("menu.producer") }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="check([{ domain: 'Producer', permisions: ['Delete'] }])"
              color="neuter"
              small
              @click="deleteItemDialog(item)"
            >
              mdi-trash-can-outline
            </v-icon>
          </template>
          <span>
            {{ $t("general.buttons.delete") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("menu.producer") }}
            </span>
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.isActive="{ item }">
        <v-icon
          small
          class="mr-4"
          color="success"
          v-if="item.isActive"
        >
          mdi-checkbox-marked-circle-outline
        </v-icon>
        <v-icon small class="mr-4" color="disabled" v-else>
          mdi-checkbox-marked-circle-outline
        </v-icon>
      </template>
      <template v-slot:item.description_text="{ item }">
        <span
          class="mr-4"
          v-if="item.description_text.toUpperCase() === 'NULL'"
        >
        </span>
        <span class="mr-4" v-else>
          <ReadMore
            :classes="'mb-0'"
            :font-size="14"
            :max-chars="20"
            :text="item.description_text"
          />
        </span>
      </template>
    </v-data-table>
    <v-row justify="center">
      <v-dialog v-model="showDialog" persistent width="600px">
        <v-card>
          <v-card-title class="headline secondary t-bw-secondary--text">{{ title }}</v-card-title>
          <v-card-text class="pa-6">{{ dialogText }}</v-card-text>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn small text color="neuter" @click="dialogClose">
              {{ $t("general.buttons.cancel") }}
            </v-btn>
            <v-btn small elevation="0" class="t-bw-error--text" color="error" @click="deleteItem">
              {{ $t("general.buttons.confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapActions, mapGetters } from "vuex";
import LanguajeService from "../../../services/LanguajeService";
import PermisionService from "../../../services/PermisionService";
import MoreOptions from "../../../components/MoreOptions";
import UploadEvidence from "../../../components/UploadEvidence";
import _ from "lodash";
import LanguageService from "@/services/LanguajeService";
import ReadMore from "@/components/ReadMoreComponent.vue";
import AddressSection from "@/components/common/AAddressForm.vue";
import MainSection from "@/module/configuration/producer/components/MainSection.vue";
import ContactSection from "@/module/configuration/producer/components/ContactSection.vue";
import GeneralSection from "@/module/configuration/producer/components/GeneralSection.vue";
import TabDialog from "@/components/common/TabDialog.vue";
import ShowMoreComponent from "@/components/ShowMoreComponent.vue";
import { getByProperty } from "@/utils";
import AddOrEditDialog from "@/module/configuration/producer/components/AddOrEditDialog.vue"

const PROFILE = JSON.parse(localStorage.getItem('profile'));

const KEYS = [
  'stringFilter',
  'locations',
  'storageDevices',
  'name',
  'cpf',
  'rg',
  'address',
  'state',
  'city',
  'country',
  'zipCode',
  'phone',
  'email',
  'isActive'
]

export default {
  components: {
    AddOrEditDialog,
    ShowMoreComponent,
    TabDialog,
    GeneralSection, ContactSection, MainSection, AddressSection, ReadMore, UploadEvidence, MoreOptions },
  data: () => ({
    profile: null,
    dialog: false,
    editedIndex: -1,
    address: null,
    editedItem: {
      name: null,
      cpf: null,
      rg: null,
      address1: null,
      address2: null,
      state: null,
      city: null,
      country: null,
      zipCode: null,
      phone: null,
      email: null,
      observations: null,
      storageDevices: [],
      isActive: true
    },
    defaultItem: {
      name: null,
      cpf: null,
      rg: null,
      address1: null,
      address2: null,
      state: null,
      city: null,
      country: null,
      zipCode: null,
      phone: null,
      email: null,
      observations: null,
      storageDevices: [],
      isActive: true
    },
    rowsPerPageItemsOptions: {
      itemsPerPageOptions: [10, 20, 50, 100, 200],
    },
    showDialog: false,
    title: "Información",
    dialogText: "Añada un texto a este dialogo",
    isEditable: true,
    max: 60,
    deletingItem: {},
    showCheckBox: false,
    selectedItems: [],
    selectedItem: null,
    isEnabledCheckBox: false,
    showItemDetailsDialog: false,
    loadingEvidences: false,
    loadingDownloadEvidences: false,
    loadingExport: false,
    options: {},
    defaultFilters: {
      companyId: PROFILE.company_id,
      pageableDTO: {
        page: 0,
        size: 10,
        sortBy: 'name',
        direction: 'asc',
      }
    },
    showTabDialog: false,
    loadingChips: {},

    tabsConfig: [
      { name: i18n.t("producer.titles.mainInformation"), icon: 'mdi-information-slab-box', slotName: 'mainInformation' },
      { name: i18n.t("producer.titles.addressInformation"), icon: 'mdi-map-marker', slotName: 'addressInformation' },
      { name: i18n.t("producer.titles.contactInformation"), icon: 'mdi-account-box', slotName: 'contactInformation' },
      { name: i18n.t("producer.titles.generalInformation"), icon: 'mdi-dots-horizontal', slotName: 'generalInformation' }
    ],
    userData: {
      name: '',
      email: '',
      address: '',
      bio: ''
    },
    onInit: true
  }),

  computed: {
    isEmptyLabels() {
      return (data) => LanguajeService.validateLabels(data);
    },
    producerFilters: {
      get() {
        return this.$store.getters['ui/GET_PRODUCER_FILTERS']
      },
      set(val) {
        this.$store.dispatch('ui/SET_PRODUCER_TO_FILTERS', val)
      },
    },
    type: {
      get() {
        return this.$store.getters['ui/GET_FILTER_TYPE']
      },
      set(val) {
        this.$store.dispatch('ui/SET_FILTER_TYPE', val)
      },
    },
    drawer: {
      get() {
        return this.$store.getters['ui/GET_DRAWER']
      },
      set(val) {
        this.$store.dispatch('ui/SET_DRAWER', val)
      },
    },
    cantFilters: {
      get() {
        return this.$store.getters['ui/GET_COUNT_FILTERS']
      },
      set(val) {
        this.$store.dispatch('ui/SET_COUNT_FILTERS', val)
      },
    },
    filtersChips: {
      get() {
        return this.$store.getters['ui/GET_FILTERS_CHIPS']
      },
      set(val) {
        this.$store.dispatch('ui/SET_FILTERS_CHIPS', val)
      },
    },
    optionsTable: {
      get() {
        return this.$store.getters['ui/GET_OPTIONS_TABLE']
      },
      set(val) {
        this.$store.dispatch('ui/SET_OPTIONS_TABLE', val)
      },
    },
    notification: {
      get() {
        return this.$store.getters["ui/GET_NOTIFICATION"];
      },
      set(val) {
        this.$store.commit("ui/NOTIFICATION", val);
      },
    },
    loading: {
      get() {
        return this.$store.getters['producer/loading']
      },
      set(val) {
        this.$store.commit('producer/SET_LOADING', val)
      },
    },
    ...mapGetters({
      source: "ui/GET_FILTER_SOURCE",
      producers: "producer/GET_PRODUCERS",
      totalFromServer: "producer/totalFromServer",
      evidences: "documents/evidences",
      requireAndMaxRules: "general/requireAndMaxRules",
      requireRules: "general/requireRules",
      lengthRules: "general/lengthRules",
      languageStructure: "general/languageStructure",
      limitEnterKeyPress: "general/limitEnterKeyPress",
      equalObjectsCompareRule: "general/equalObjectsCompareRule",
    }),
    headers: () => [
      { text: i18n.t("producer.fields.name"), value: "name", width: 150, sortable: false},
      { text: i18n.t("producer.fields.locations"), value: "storageDevices", width: 250, sortable: false},
      { text: i18n.t("producer.fields.observations"), value: "observations", width: 200, sortable: false},
      { text: i18n.t("producer.fields.isActive"), value: "isActive", width: 100, sortable: false},
      {
        text: i18n.t("general.fields.action"),
        value: "action",
        width: 120,
        sortable: false,
        align: "center",
      },
    ],
    formTitle() {
      return (
        (this.editedIndex === -1
          ? i18n.t("general.titles.new")
          : i18n.t("general.titles.edit")) +
        " " +
        i18n.t("producer.titles.new_producer")
      );
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    options: {
      async handler() {
        this.optionsTable = this.options
        if (!this.onInit)
          await this.searchText()
      },
      deep: true,
    },
  },

  created() {
    this.initialize();
  },

  async mounted() {
    this.type = 'producer'
    this.$store.commit('ui/PRODUCER_FILTERS_ORIGINAL_STATE')

    let filtersProducer = JSON.parse(localStorage.getItem('filtersProducer'))

    this.$root.$emit('set:locations:filters-producer', {
      locations: filtersProducer.locations,
      storageDevice: filtersProducer.storages
    });

    this.producerFilters = {...filtersProducer}

    this.$root.$on('setFiltersProducer', () => {
      this.closeFilterDialog()
    })

    if (this.onInit) {
      await this.applyFilters().finally(() => {
        this.filteredChips()
        this.getCantFilters()

        this.onInit = false;
      })
    }
  },

  methods: {
    ...mapActions({
      fetchListProducer: "producer/fetchListProducer",
      saveProducer: "producer/saveProducer",
      deleteProducer: "producer/deleteProducer",
      clearEvidences: "documents/clearEvidences",
      exportCSV: "producer/exportCSV",
    }),
    cleanFilter(chip, index) {
      this.$set(this.loadingChips, index, true);

      let tag = chip.value;
      let filtersProducer = JSON.parse(localStorage.getItem('filtersProducer') || '{}');

      if (tag in this.producerFilters) {
        if (Array.isArray(this.producerFilters[tag])) {
          if (['storages', 'locations'].includes(tag)) {
            if (tag === 'locations') {
              filtersProducer['locations'] = [];
              this.producerFilters['locations'] = [];
              filtersProducer['storageDevices'] = [];
              this.producerFilters['storageDevices'] = [];
            }

            if (tag === 'storageDevices') {
              filtersProducer['storageDevices'] = [];
              this.producerFilters['storageDevices'] = [];
            }
          } else {
            filtersProducer[tag] = [];
            this.producerFilters[tag] = [];
          }
        } else {
          filtersProducer[tag] = null;
          this.producerFilters[tag] = null;
        }
      }

      console.log(tag)
      if (tag === 'locations') {
        this.$root.$emit("clear:locations:filters-producer", {
          locations: [],
          storageDevice: []
        });
      }
      if (tag === 'storageDevices') {
        this.$root.$emit("clear:locations:filters-producer", {
          locations: filtersProducer.locations,
          storageDevice: []
        });
      }

      localStorage.setItem('filtersProducer', JSON.stringify(filtersProducer));

      this.applyFilters().finally(() => {
        this.$set(this.loadingChips, index, false);
        this.filteredChips();
        this.getCantFilters();
      });
    },
    async applyFilters() {
      this.loading = true;
      let filtersProducer = JSON.parse(localStorage.getItem('filtersProducer'))

      if (filtersProducer) {
        this.producerFilters = {...filtersProducer}
      }

      const direction =
        this.options.sortDesc.length === 0 || this.options.sortDesc[0]
          ? 'desc'
          : 'asc'

      const sort =
        this.options.sortBy.length === 0
          ? "name"
          : _.filter(this.headers, {value: this.options.sortBy[0]})[0].val;

      this.$set(this.producerFilters, 'pageableDTO', {
        page: this.options.page > 0 ? this.options.page - 1 : 0,
        size: this.options.itemsPerPage > -1 ? this.options.itemsPerPage : this.totalFromServer,
        sortBy: sort,
        direction: direction,
      })

      await this.fetchListProducer([this.producerFilters, this.$toast]).finally(() => {
        this.loading = false
      })
    },
    async closeFilterDialog() {
      this.producerFilters.stringFilter = "";
      await this.applyFilters()
      this.filteredChips()
      this.getCantFilters()
    },
    async openFilters() {
      this.type = 'producer'

      let filtersProducer = JSON.parse(localStorage.getItem('filtersProducer'))

      console.log(filtersProducer)
      await this.$root.$emit("set:locations:filters-producer", {
        locations: filtersProducer.locations,
        storageDevice: filtersProducer.storageDevices
      })

      this.drawer = true
    },
    filteredChips() {
      let chips = []

      _.forEach(_.pick(this.producerFilters, KEYS), (value, key) => {
        if (key === 'locations' && (value && value.length > 0)) {
          chips.push({
            value: key,
            name: i18n.t("documents.fields.locations"),
          })
        } else if (key === 'storageDevices' && (value && value.length > 0)) {
          chips.push({
            value: key,
            name: i18n.t("documents.fields_add.storage_device"),
          })
        } else if (key === 'stringFilter' && (value && value !== '')) {
          chips.push({
            value: key,
            name: i18n.t("general.fields.text"),
          })
        } else if (typeof this.producerFilters.isActive === 'boolean' && key === 'isActive') {
          chips.push({
            value: key,
            name: i18n.t("general.fields.active"),
          })
        } else if (value && value.length > 0) {
          chips.push({
            value: key,
            name: i18n.t(`producer.fields.${key}`),
          })
        }
      })

      this.filtersChips = chips
    },
    getCantFilters() {
      let cant = 0
      _.forEach(_.pick(this.producerFilters, KEYS), (value, key) => {
        if (['object', 'string', 'boolean', 'number'].includes(typeof value) && (value && value.length > 0))
          cant += 1
      })
      this.cantFilters = cant
    },
    async handleSave() {
      if (
        this.equalObjectsCompareRule(this.selectedItem, this.editedItem)
      ) {
        this.$toast.info(i18n.t("general.notification.nothingToModify"), {
          icon: 'mdi-information-slab-circle',
          queueable: true
        });
      } else {
        if (!this.editedItem.observations)
          this.editedItem.observations =
            this.isEmptyLabels(this.editedItem.observations)
              ? ""
              : this.editedItem.observations;

        let item = Object.assign({}, {...this.editedItem});
        item.observations = LanguajeService.setKey3(
          item.observations,
          item.observations,
          this.languageStructure
        );

        item.companyId = this.profile.company_id;

        item = {
          ...item,
          storageDevices: getByProperty(item.storageDevices, 'storageDeviceId'),
          country: item.country ? item.country.name : null,
          state: item.state ? item.state.name : null,
          city: item.city ? item.city.name : null
        }

        console.log('Saving data:', {...item});

        this.loading = true;

        await this.saveProducer([
          item,
          this.editedIndex,
          this.$toast,
        ]).finally(() => {
          console.log(this.defaultFilters)
          this.fetchListProducer([this.defaultFilters, this.$toast]);
          this.close();
        });
      }
    },
    translate(key, uppercase) {
      return LanguageService.getKey3(key)
    },
    async searchAll() {
      let filters = {
        companyId: null,
        stringFilter: null,
        pageableDTO: {
          page: 0,
          size: 10,
          sortBy: 'name',
          direction: 'asc',
        }
      }

      filters.companyId = this.profile.company_id

      await this.fetchListProducer([filters, this.$toast])
    },
    async searchForText() {
      this.options.page = 1
      await this.searchText()
    },
    async searchText() {
      let filters = {
        ...this.producerFilters,
        stringFilter: null,
        pageableDTO: {
          page: null,
          size: null,
          sortBy: 'name',
          direction: 'asc'
        }
      }

      const direction =
        this.options.sortDesc.length === 0 || this.options.sortDesc[0]
          ? 'desc'
          : 'asc'

      const sort =
        this.options.sortBy.length === 0
          ? 'name'
          : _.filter(this.headers, { value: this.options.sortBy[0] })[0].value
      filters.pageableDTO = {
        page: this.options.page - 1,
        size: this.options.itemsPerPage,
        sortBy: sort,
        direction: direction,
      }

      if (this.producerFilters.stringFilter && this.producerFilters.stringFilter !== '')
        filters.stringFilter = this.producerFilters.stringFilter

      await this.fetchListProducer([filters, this.$toast])
    },
    initialize() {
      this.profile = JSON.parse(localStorage.getItem("profile"));
    },
    showOrEditItem(item, isEditable, isNew) {
      this.isEditable = isEditable;

      if (isNew) item.id = null;

      this.selectedItem = {...item};
      this.editedIndex = this.producers.indexOf(item);

      this.editedItem = Object.assign({}, {
        ...item,
        observations: this.translate(item.observations),
      });
      this.showTabDialog = true;
    },
    async exportCSVProducers() {
      this.loadingExport = true;
      const data = {
        ...this.producerFilters,
        language: LanguajeService.getLenguajeName(),
        ids: getByProperty(this.selectedItems, "id"),
      };
      await this.exportCSV([data, this.$toast]).finally(() => {
        this.loadingExport = false;
      });
    },
    checkBoxShow() {
      this.showCheckBox = !this.showCheckBox;
      if (!this.showCheckBox) this.selectedItems = [];
    },
    check(permissions) {
      return PermisionService.check(permissions);
    },
    deleteItem() {
      this.dialogClose();
      this.deleteProducer([this.deletingItem.id, this.$toast]).finally(() => {
        this.options.page = 1;
        this.fetchListProducer([this.producerFilters, this.$toast]);
      });
    },
    close() {
      this.$root.$emit('clear:form');

      this.showTabDialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.$root.$emit(`set:locations:filters-${this.source}`, {
        locations: [],
        storageDevice: []
      });
      this.loading = false;
    },
    dialogShow(params) {
      this.title = params.title;
      this.dialogText = params.dialogText;
      this.showDialog = true;
    },
    dialogClose() {
      this.showDialog = false;
    },
    deleteItemDialog(item) {
      this.deletingItem = item;
      this.dialogShow({
        title: i18n.t("general.titles.alert"),
        dialogText: i18n.t("general.notification.deleteItems"),
      });
    },
    replace(event){
      event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
    },
  },
  destroyed() {
    this.notification = false;
    this.onInit = true;

    this.$root.$off('setFiltersProducer');

    this.$root.$emit("clear:locations:filters", {
      locations: [],
      storageDevice: []
    })

    this.$store.commit('ui/PRODUCER_FILTERS_ORIGINAL_STATE');
  },
};
</script>

<style scoped>
.v-data-table {
  margin: 25px;
}

>>> .mapboxgl-map canvas {
  border-radius: 8px !important;
}
:deep(.v-chip-group .v-slide-group__content) {
  display: flex !important;
  justify-content: flex-start !important;
}
:deep(.v-toolbar__content) {
  padding: 0 !important;
}
</style>
